<template>
    <div class="purchasesVideoList">
        <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
            @onRefresh="onRefresh" :isHigehtMax="true" :isNoData="isNoData" :error="error">
            <VideoList class="videoL" :dataList="list" />
        </PullRefresh>
    </div>
</template>

<script>
    import VideoList from "@/components/VideoList";
    import PullRefresh from "@/components/PullRefresh";
    import {
        queryBuyList
    } from "@/api/mine";
    export default {
        name: 'PurchasesVideoList',
        components: {
            VideoList,
            PullRefresh
        },
        data() {
            return {
                list: [], // 列表
                pageNumber: 1, // 页码
                pageSize: 10, // 条数
                loading: true, // loading
                refreshing: false, // 刷新状态
                finished: false, // 下拉状态
                error: false, // 是否显示错误信息
                isNoData: false, // 暂无数据
            }
        },
        created() {
            this.getList();
        },
        methods: {
            // 查询列表
            async getList(type) {
                let req = {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                    newsType: "SP", // 影视-MOVIE 图文-img 视频-SP 话题-tag
                    // uid: JSON.parse(getSessionItem("userInfo")).uid,
                }
                try {
                    let res = await this.$Api(queryBuyList, req);
                    this.loading = false;
                    this.refreshing = false;
                    if (res.code === 200) {
                        let list = res.data.list || [];
                        if (type === 'refresh') {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        // this.isNoData = this.comList.length > 0 ? false : true;
                        if (this.list.length <= 0) {
                            this.isNoData = true;
                        }
                        if (!res.data.hasNext) {
                            this.finished = true;
                        }
                    } else {
                        this.error = true;
                    }
                } catch (error) {
                    this.loading = false;
                    this.error = true;
                }

            },
            // 上拉加载
            onLoad() {
                this.loading = true;
                if (this.error) {
                    this.error = false;
                } else {
                    this.pageNumber++
                }
                this.getList();
            },
            // 下拉刷新
            onRefresh() {
                this.pageNumber = 1;
                this.refreshing = true;
                this.finished = false;
                this.loading = true;
                this.getList("refresh");
            },
        }
    }
</script>

<style lang="scss" scoped>
    .purchasesVideoList {
        height: calc(100% - 92px);
        overflow-y: auto;
      -webkit-overflow-scrolling: touch;
        .videoL {
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
        }
    }
</style>
